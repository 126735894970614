.booking-container {
    display: flex;
    gap: 20px;
    font-family: Arial, sans-serif;
  }
  
  .calendar-container {
    width: 100%;
    max-width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .month-year {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .nav-buttons {
    display: flex;
    gap: 10px;
  }
  
  .nav-button {
    background: #1F484C;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
  }
  
  .calendar-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .calendar-table th {
    padding: 10px;
    text-align: center;
    font-weight: normal;
    color: #757575;
  }
  
  .calendar-day {
    padding: 10px;
    text-align: center;
    border: 1px solid #e0e0e0;
    cursor: pointer;
  }
  
  .calendar-day:hover {
    background-color: #f0f0f0;
  }
  
  .calendar-day.other-month {
    color: #ccc;
  }
  
  .calendar-day.selected {
    background-color: #1F484C;
    color: white;
  }
  .times-container {
    width: 450px;
  }
  
  .times-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .time-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .time-button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: white;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .time-button:hover {
    background-color: #f0f0f0;
  }
  
  /* Add this to your CSS file (e.g., Calendar.css) */
  .selected {
      background-color: #4caf50; /* Change this to whatever color you want */
      color: white; /* Optional: change text color */
      border-color: #4caf50; /* Optional: if you want to change the border */
      transition: background-color 0.3s ease, color 0.3s ease;
  }

  
  .book-session-button {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    background-color: #1F484C;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .book-session-button:hover {
    background-color: #163638;
  }
  
  .book-session-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }