.black-button-link {
    text-decoration: none;
    cursor: pointer;
    z-index: 5;
  }
  .black-button {
    background-color: black;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .black-button:hover {
    background-color: #e56251;
  }
  
  .arrow {
    margin-left: 8px;
  }
  .backarrow {
    margin-right: 8px;
  }

  .back-black-button {
    background-color: black;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
  }
  
  .back-black-button:hover {
    background-color: #e56251;
  }