body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

/* light and dark mode */
/* body */
body.light {
  background-color: #FEFBF6;
}
body.dark {
  background-color: #1f1f1f;
}
/* footer */
footer.light {
  background-color: white;
  padding: '2rem 0',
}
footer.dark {
  background-color: #171717;
  padding: '2rem 0',
}

footer{
  background-color: #fff
}
/* Card */

main {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.5%;
}
.circle {
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: -1;
  max-height: 100%;
  max-width: 100%;
}

/* Global styles for light and dark modes */
button {
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

/* Button styling based on the light/dark theme */
body.light button {
  /* background-color: #000000;
  color: white; */
}

body.dark button {
  background-color: #ffffff;
  color: black;
}

a.standardLink {
  color: #171717;
  text-decoration: none;
}

a.standardLink:hover{
  color: orange;
}

.required-field::before {
  content: "*";
  color: red;
}